// react
import { useLayoutEffect, useCallback } from 'react';

export default (isVisible: boolean): void => {
  const getScrollWidth = (): number => {
    return window.innerWidth - document.documentElement.clientWidth;
  };

  const lockBody = useCallback(() => {
    const bodyStyle = document.body.style;
    bodyStyle.marginLeft = -getScrollWidth() + 'px';
    bodyStyle.overflowY = 'hidden';
  }, []);

  const unlockBody = (originalOverflow: string): void => {
    const bodyStyle = document.body.style;

    bodyStyle.overflow = originalOverflow;
    bodyStyle.marginLeft = 'initial';
  };

  useLayoutEffect((): (() => void) => {
    // Get original body overflow
    const originalOverflow = window.getComputedStyle(document.body).overflow;
    // Prevent scrolling on mount
    if (isVisible) lockBody();
    // Re-enable scrolling when component unmounts
    return (): void => {
      unlockBody(originalOverflow);
    };
  }, [isVisible, lockBody]);
};
