// react
import React from 'react';
import Modal from 'react-modal';
// hooks
import { IUseModal } from 'hooks/useModal';
import useLockBodyScroll from 'hooks/useLockBodyScroll';
//styles
import styles from './modal.module.css';

interface IModal extends IUseModal {
  isCloseBtnVisible?: boolean;
}

type TModal = Omit<IModal, 'openModal'>;

export interface IModalContextValue {
  openModal: () => void;
  closeModal: () => void;
  setModalContent: React.Dispatch<React.SetStateAction<JSX.Element | null>>;
  setWithCloseBtn: React.Dispatch<React.SetStateAction<boolean>>;
  modalContent: any;
  isOpen: boolean;
  displayDuration: number | null;
  setDisplayDuration: React.Dispatch<React.SetStateAction<number | null>>;
}

export const ModalContext = React.createContext({} as IModalContextValue);

Modal.setAppElement(`#___gatsby`);
const CustomModal: React.FC = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [modalContent, setModalContent] = React.useState<JSX.Element | null>(null);
  const [withCloseBtn, setWithCloseBtn] = React.useState(false);

  const [displayDuration, setDisplayDuration] = React.useState<number | null>(null);

  const openModal = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
    setModalContent(null);
    setDisplayDuration(null);
  };

  useLockBodyScroll(isOpen);

  return (
    <>
      <ModalContext.Provider
        value={{
          openModal,
          closeModal,
          setModalContent,
          modalContent,
          isOpen,
          setWithCloseBtn,
          displayDuration,
          setDisplayDuration,
        }}
      >
        {children}
        <Modal isOpen={isOpen} className={styles.modal} overlayClassName={styles.overlay}>
          {withCloseBtn && (
            <button
              className={styles.closeBtn}
              onClick={closeModal}
              // aria-label="close Modal"
            >
              &times;
            </button>
          )}
          {modalContent}
        </Modal>
      </ModalContext.Provider>
    </>
  );
};

export default CustomModal;
