import React, { useEffect } from 'react';
import { useLocation } from '@reach/router';
import queryString from 'query-string';
// styles
import './layout.css';
// components
import SEO, { IMainMeta } from 'components/seo';
import { IBlogMeta, IFaqMeta } from 'components/seo/schemaOrg';
import Navigation from './header/nav';
import ModalProvider from 'components/modal';
import GoTopButton from './go-top-button';

export interface IMetaData {
  main: IMainMeta;
  blog?: IBlogMeta;
  faq?: IFaqMeta[];
}

interface Props {
  title?: string | JSX.Element;
  children: React.ReactNode;
  navBtnTextColor?: string;
  isRed?: boolean;
  metaData: IMetaData;
}

function Layout({ metaData, children, isRed = false, navBtnTextColor = '#000' }: Props) {
  const location = useLocation();

  const queryData = queryString.parse(location.search);

  useEffect(() => {
    if (queryData?.utm_source === 'google_search' || queryData?.utm_source === 'google') {
      localStorage?.setItem('fromAds', 'true');
    }
    document.documentElement.style.setProperty('--nav-btn-text-color', navBtnTextColor);
  }, []);

  return (
    <ModalProvider>
      <React.Fragment>
        <SEO mainMetaData={metaData.main} blogMetaData={metaData.blog} faqMetaData={metaData.faq} />
        <Navigation isRed={isRed} />
        {children}
        <GoTopButton />
      </React.Fragment>
    </ModalProvider>
  );
}

export default Layout;
