import React from 'react';
//components
import Menu from '../../menu';
import MapModal from '../../../../pop-ups/map-modal';
import ViewOnMap from '../../view-on-map';
import useModal from 'hooks/useModal';
//data
import mainOffice from '../../address.json';
//interfaces
import { IMapModal } from '../../../../pop-ups/map-modal';
// styles
import clsx from 'clsx';
import styles from './office-menu.module.css';

interface IOfficeMenu {
  extraOffices: {
    address: {
      location: string;
      postcode: string;
    };
    onMap?: IMapModal;
  }[];
}

function AddressItem(e: { title: string; second: string }) {
  return (
    <div className={styles.address}>
      <div>{e.title}</div>
      <div>{e.second}</div>
    </div>
  );
}

const OfficeMenu = ({ extraOffices }: IOfficeMenu) => {
  const { handleModal } = useModal();

  return (
    <div className={clsx(styles.column, styles.officeList)}>
      {/* <Menu title="Offices" list={mainOffice} getItem={AddressItem} /> 
       <a
        className={styles.mapLink}
        onClick={() => {
          handleModal(<ViewOnMap />);
        }}
      >
        View on map
      </a> */}
      {/*  commented part temporary swapped to this / Start swapped part */}
      <Menu
        title="Offices"
        list={[
          {
            title: '40000 Sumy, UA',
            second: '',
          },
        ]}
        getItem={AddressItem}
      />
      <br />
      {/* End swapped part */}
      <ul>
        {extraOffices?.map(({ address, onMap }) => (
          <li key={address.location} className={styles.item}>
            <p>{address?.location}</p>
            <p>{address?.postcode}</p>
            {/* {onMap && (
              <a
                className={styles.mapLink}
                onClick={() => {
                  handleModal(
                    <MapModal center={onMap.center} zoom={onMap?.zoom} location="Tallinn" />
                  );
                }}
              >
                View on map
              </a>
            )} */}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default OfficeMenu;
