import React, { useEffect, createRef } from 'react';
import Identificators from '../../../googleAnalyticsIdentificators';
import { Link } from 'gatsby';
import Item, { LinkItem, LinkList } from './Item';
import clsx from 'clsx';
import styles from './nav.module.css';
import Social from '../social';
import { defaultMenu } from './menu';

interface Props {
  isRed?: boolean;
}

function Navigation({ isRed }: Props) {
  const [isOpen, setOpen] = React.useState(false);
  const [active, setActive] = React.useState<string | undefined>();
  const [isScrolled, setScrolled] = React.useState(false);

  const observableRef = createRef<HTMLDivElement>();

  const menu = React.useMemo(() => defaultMenu as (LinkItem | LinkList)[], []);

  const toggleOpen = () => {
    document.body.style.overflowY = !isOpen ? 'hidden' : 'scroll';
    setOpen(!isOpen);
  };

  useEffect(() => {
    const container = observableRef.current;

    const options = {
      root: null,
      rootMargin: '0px 0px -100px 0px',
      threshold: 0,
    };
    const onEntry = (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting) {
        setScrolled(false);
      } else if (!entry.isIntersecting) {
        setScrolled(true);
      }
    };

    const observer = new IntersectionObserver(onEntry, options);

    //@ts-ignore
    container && observer.observe(container);

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <div
        className={clsx(styles.container, {
          [styles.scrolled]: isScrolled,
          [styles.red]: isRed,
        })}
      >
        <div className={styles.content}>
          <div className={styles.wrapper}>
            <div className={clsx(styles.drawer, { [styles.open]: isOpen })}>
              <nav>
                <div className={styles.menu}>
                  {menu.map((e) => (
                    <Item key={e.title} setActive={setActive} active={active} {...e} />
                  ))}
                  <Item
                    setActive={setActive}
                    id={Identificators.FOOTER_GET_IN_TOUCH}
                    title="Get In Touch"
                    to="/get-in-touch/"
                  />
                </div>
              </nav>
              <div className={styles.bottom}>
                <Link className={styles.term} to="/">
                  Terms &amp; Conditions
                </Link>
                <Link className={styles.term} to="/">
                  Privacy Policy
                </Link>
              </div>
              <Social />
            </div>
          </div>
          <div className={clsx(styles.top, { [styles.topOpen]: isOpen })}>
            <Link
              to="/"
              className={clsx(styles.logoLink, { [styles.red]: isRed || isOpen })}
              aria-label="Go to the home page"
            />
            <nav
              className={styles.nav}
              itemScope
              itemType="http://schema.org/SiteNavigationElement"
            >
              <div className={styles.menu}>
                {menu.map((e) => (
                  <Item key={e.title} setActive={setActive} active={active} {...e} />
                ))}
              </div>
              <Link
                id={Identificators.NAVIGATION_GET_IN_TOUCH}
                className={styles.btn}
                to="/get-in-touch/"
              >
                Get in touch
              </Link>
            </nav>
            <button
              type="button"
              className={clsx(styles.burger, { [styles.open]: isOpen })}
              onClick={toggleOpen}
              aria-label="Menu"
            >
              <span />
            </button>
          </div>
        </div>
      </div>
      <div className={styles.observable} ref={observableRef}>
        <div></div>
      </div>
    </>
  );
}

export default Navigation;
