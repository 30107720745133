import * as React from 'react';
import clsx from 'clsx';
import styles from './social.module.css';
import list from '../../footer/social/list.json';

function Social() {
  return (
    <ul className={styles.list}>
      {list.map((e) => (
        <li key={e.title.toLowerCase()} className={styles.item}>
          <a
            className={clsx(styles.link, styles[e.title.toLowerCase()])}
            href={e.to}
            rel="noopener noreferrer"
            // aria-label={e.title}
          />
        </li>
      ))}
    </ul>
  );
}

export default Social;
