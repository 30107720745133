import * as React from 'react';
import { Link } from 'gatsby';
// styles
import clsx from 'clsx';
import styles from './footer.module.css';
//components
import Social from './social';
import Menu from './menu';
import OfficeMenu from './menu/office-menu';
import tech from './technology';
import extraOffices from './additional-address.json';

const Routes = require('../../routes').Routes;

const MENU = [
  {
    title: 'About us',
    to: '/about-us/',
  },
  {
    title: 'Cases',
    to: Routes.CASE_STUDIES,
  },
  {
    title: 'Services',
    to: Routes.SERVICES_MVP_DEVELOPMENT,
  },
  {
    title: 'Partnership',
    to: Routes.PARTNERSHIP,
  },
  {
    title: 'Blog',
    to: Routes.BLOG,
  },
];

function MenuItem(e: { title: string; to: string }) {
  return (
    <Link to={e.to} className={styles.link} itemProp="url">
      {e.title}
    </Link>
  );
}

function Footer({ isPrevBgdWtite = false }) {
  const fullYear = new Date().getFullYear();

  return (
    <footer
      className={isPrevBgdWtite ? styles.container : styles.grayContainer}
      itemScope
      itemType="http://schema.org/WPFooter"
    >
      <div className="inner">
        <div className={styles.top}>
          <div className={styles.column}>
            <Menu title="Menu" list={MENU} getItem={MenuItem} />
          </div>
          <div className={styles.column}>
            <Menu title="Technologies" list={tech} getItem={MenuItem} />
          </div>
          <div className={clsx(styles.column, styles.officeList)}>
            <OfficeMenu extraOffices={extraOffices} />
          </div>
          <div className={styles.column} itemScope itemType="http://schema.org/Organization">
            <p className={styles.title}>Say hello</p>
            {/* <div className={styles.address}>
              <a href="mailto:talk@brocoders.team" className={styles.mainLink} itemProp="email">
                talk@brocoders.team
              </a>
            </div> */}
            <div className={styles.item}>
              <a href="tel:+380665773446" className={styles.link} itemProp="telephone">
                {/* +38 050 768 76 91 */}
                +38 068 747 53 86
              </a>
              <br />
              <br />
              <a
                href="tel:+3726028547"
                // className={clsx(styles.link, styles.estonianPhone)} //temporary
                className={styles.link}
                itemProp="telephone"
              >
                +3 726 028 547
              </a>
            </div>
            {/*  this part with e-mail has been this temporarily moved from the top */}
            <br />
            <div className={styles.address}>
              <a href="mailto:talk@brocoders.team" className={styles.mainLink} itemProp="email">
                talk@brocoders.team
              </a>
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <span className={styles.copy}> © 2011-{fullYear} Brocoders.com </span>
          <div className={styles.privacy}>
            <Link className={clsx(styles.link, styles.privacyLink)} to="/terms-of-use">
              Terms &amp; Conditions
            </Link>
            <Link className={clsx(styles.link, styles.privacyLink)} to="/privacy-policy">
              Privacy Policy
            </Link>
            <Link className={clsx(styles.link, styles.privacyLink)} to="/slavery-statement-policy">
              Modern Slavery Policy
            </Link>
            <Link className={clsx(styles.link, styles.privacyLink)} to="/supply-chain-policy">
              Supply Chain Policy
            </Link>
          </div>
          <Social />
        </div>
      </div>
    </footer>
  );
}

export default Footer;
