const Routes = require('../../../routes').Routes;

export const defaultMenu = [
  {
    title: 'About us',
    to: '/about-us/',
  },
  {
    title: 'Careers',
    to: Routes.CAREER,
  },
  {
    title: 'Cases',
    to: Routes.CASE_STUDIES,
  },
  {
    title: 'Services',
    list: [
      {
        title: 'AI Development',
        to: Routes.SERVICES_AI,
      },
      {
        title: 'Team augmentation',
        to: Routes.SERVICES_TEAM_AUGMENTATION,
      },
      {
        title: 'MVP development',
        to: Routes.SERVICES_MVP_DEVELOPMENT,
      },
      {
        title: 'SaaS development',
        to: Routes.SERVICES_SAAS_DEVELOPMENT,
      },
      {
        title: 'Dedicated team',
        to: Routes.SERVICES_DEDICATED_TEAM,
      },
      {
        title: 'DevOps services',
        to: Routes.SERVICES_DEVOPS,
      },
      {
        title: 'Software testing',
        to: Routes.SERVICES_QA,
      },
      // {
      //   title: 'Product development',
      //   to: Routes.SERVICES_PRODUCT_DEVELOPMENT,
      // },
      // {
      //   title: 'Digital transformation',
      //   to: Routes.SERVICES_DIGITAL_TRANSFORMATION,
      // },
      // {
      //   title: 'Mobile app development',
      //   to: Routes.SERVICES_MOBILE_APP_DEVELOPMENT,
      // },
      // {
      //   title: 'Web app development',
      //   to: Routes.SERVICES_WEB_APP_DEVELOPMENT,
      // },
    ],
  },
  {
    title: 'Industries',
    list: [
      {
        title: 'Agritech',
        to: Routes.INDUSTRIES_AGRITECH,
      },
      {
        title: 'Martech',
        to: Routes.INDUSTRIES_MARTECH,
      },
      {
        title: 'Fintech',
        to: Routes.INDUSTRIES_FINTECH,
      },
      {
        title: 'Ride-sharing',
        to: Routes.INDUSTRIES_RIDESHARING,
      },
      {
        title: 'Fitness & Healthcare',
        to: Routes.FITNESS_MOBILE_SOLUTIONS_B,
      },
      {
        title: 'Foodtech',
        to: Routes.INDUSTRIES_FOODTECH,
      },
      {
        title: 'Edtech',
        to: Routes.INDUSTRIES_EDTECH,
      },
      {
        title: 'Proptech',
        to: Routes.INDUSTRIES_PROPTECH,
      },
      {
        title: 'Events management',
        to: Routes.INDUSTRIES_EVENTS_MANAGMENT,
      },
    ],
  },
  {
    title: 'Technologies',
    list: [
      {
        title: 'Node.js',
        to: Routes.TECHNOLOGIES_NODEJS,
      },
      {
        title: 'React.js',
        to: Routes.TECHNOLOGIES_REACTJS,
      },
      {
        title: 'React Native',
        to: Routes.TECHNOLOGIES_REACT_NATIVE_JS,
      },
      {
        title: 'Gatsby.js',
        to: Routes.TECHNOLOGIES_GATSBY,
      },
      {
        title: 'Blockchain',
        to: Routes.TECHNOLOGIES_BLOCKCHAIN,
      },
    ],
  },
  {
    title: 'Blog',
    to: Routes.BLOG,
  },
];
