import * as React from 'react';
import styles from './menu.module.css';

interface Item {
  title: string;
}

interface Props<T> {
  list: ReadonlyArray<T>;
  title: string;
  getItem: (data: T) => React.ReactNode;
}

function Menu<T extends Item>({ list, title, getItem }: Props<T>) {
  return (
    <div className={styles.container} itemScope itemType="http://schema.org/SiteNavigationElement">
      <p className={styles.title}>{title}</p>
      <ul className={styles.list}>
        {list.map((e) => (
          <li key={e.title} className={styles.item}>
            {getItem(e)}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Menu;
