import React from 'react';
import { Link } from 'gatsby';
import clsx from 'clsx';
import styles from './nav.module.css';
interface LinkControl {
  title: string;
}

interface LinkAPI {
  active?: string;
  setActive(a: string | undefined): void;
  customStyle?: string;
}

export interface LinkItem extends LinkControl {
  to: string;
  id?: string;
}

export interface LinkList extends LinkControl {
  list: ReadonlyArray<LinkItem>;
  id?: string;
}

type ItemProps = (LinkItem & LinkAPI) | (LinkList & LinkAPI);

function Item({ title, active, id, setActive, customStyle, ...rest }: ItemProps) {
  const isDoubledColumn = title === 'Industries';

  if ('list' in rest) {
    const maxHeight = rest.list.length * 25 + 60; // for styles in doubled list
    return (
      <div className={clsx(styles.submenu, { [styles.current]: title === active })}>
        <button
          type="button"
          onClick={() => setActive(active === title ? undefined : title)}
          className={clsx(styles.link)}
          style={{ cursor: 'default' }}
        >
          {title}
        </button>

        <ul
          className={isDoubledColumn ? styles.doubledList : styles.list}
          style={{ '--max-height': `${maxHeight}px` } as React.CSSProperties}
          itemScope
          itemType="http://schema.org/SiteNavigationElement"
        >
          {rest.list.map((e, _, arr) => (
            <li key={e.title} className={styles.item}>
              <Item title={e.title} to={e.to} setActive={() => {}} />
            </li>
          ))}
          {rest.list.length % 2 !== 0 && isDoubledColumn && (
            <li key="odd" className={styles.item}>
              <div className={styles.empty} />
            </li>
          )}
        </ul>
      </div>
    );
  }
  return (
    <Link
      className={customStyle ? customStyle : styles.link}
      id={id}
      to={rest.to}
      activeClassName={styles.activeRoute}
      aria-label={id}
    >
      {title}
    </Link>
  );
}

export default Item;
