const Routes = require('../../routes').Routes;

export default [
  {
    title: 'Node.js',
    to: Routes.TECHNOLOGIES_NODEJS,
  },
  {
    title: 'React.js',
    to: Routes.TECHNOLOGIES_REACTJS,
  },
  {
    title: 'React Native',
    to: Routes.TECHNOLOGIES_REACT_NATIVE_JS,
  },
  {
    title: 'Gatsby.js',
    to: Routes.TECHNOLOGIES_GATSBY,
  },
  {
    title: 'Blockchain',
    to: Routes.TECHNOLOGIES_BLOCKCHAIN,
  },
];
