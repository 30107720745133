import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import throttle from 'lodash/throttle';
import styles from './go-top-button.module.css';
import ArrowTopIcon from './arrow-top.inline.svg';

const GoTopButton = () => {
  const [isScrollButtonVisible, setIsScrollButtonVisible] = useState(false);

  const handleGoTopButtonClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const changeSetForScroll = throttle(() => {
    setIsScrollButtonVisible(window.scrollY >= window.innerHeight);
  }, 500);

  useEffect(() => {
    window.addEventListener('scroll', changeSetForScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', changeSetForScroll);
    };
  }, []);

  return (
    <button
      type="submit"
      className={clsx(styles.button, {
        [styles.invisible]: !isScrollButtonVisible,
      })}
      onClick={handleGoTopButtonClick}
      aria-label="Go Top"
    >
      <ArrowTopIcon />
    </button>
  );
};

export default GoTopButton;
