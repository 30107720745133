// react
import React, { useContext, useEffect, useRef } from 'react';
// context
import { ModalContext } from 'components/modal';
export interface IUseModal {
  handleModal: (
    content?: JSX.Element | null,
    withModalCloseBtn?: boolean,
    displayDuration?: number
  ) => void;
  isOpen: boolean;
}

const useModal = (): IUseModal => {
  const {
    openModal,
    closeModal,
    setModalContent,
    setWithCloseBtn,
    modalContent,
    isOpen,
    displayDuration,
    setDisplayDuration,
  } = useContext(ModalContext);
  const handleModal = (
    content: JSX.Element | null = null,
    withModalCloseBtn = true,
    durationOfDisplay: number | null = null
  ) => {
    durationOfDisplay && setDisplayDuration(durationOfDisplay);
    setModalContent(content);
    content && setWithCloseBtn(withModalCloseBtn);
  };

  const timerRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (modalContent) {
      openModal();
      if (displayDuration) {
        timerRef.current = setTimeout(() => {
          closeModal();
          setDisplayDuration(null);
        }, displayDuration);
        return () => clearTimeout(timerRef.current as ReturnType<typeof setTimeout>);
      }
    } else {
      closeModal();
    }
  }, [modalContent]);

  return {
    handleModal,
    isOpen,
  };
};

export default useModal;
